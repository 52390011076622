@import "utils.scss";
@import "rtl.scss";
@import "../../node_modules/font-awesome/css/font-awesome.css";



    // logo
    $logo: '/assets/images/logo.png';
   
    // font
    $font-family: 'Open Sans', sans-serif;
   
    // login-backroud 
    $login-background: url('/assets/images/login_back.jpg') center;
    
    // main page header font size
    $main-page-header-font-size: 2rem;
   
    // table header font size
    $table-header-font-size: .875em;
   
    // accents color
    $accent-color : #354355;  

    // tabel, chart buttons and chart panel border
    $tabel-accrordion-header: #53AF32;

    //  menu background
    $menu-background-color: #53AF32;

    // Submenu items (grouped) background color
    $submenu-items-background-color: #627997;

    // Active menu item background
    $active-menu-item-background-color: lighten($submenu-items-background-color, 5%);

    // table stripped row color
    $table-stripped-row-background-color: #f9f9f9; 
    // #ededed;

    // breadcrumb background color
    $breadcrumb-background-color: #e9ecef;


    // ** buttons **
    $btn-primary-background-color: $accent-color;
    $btn-primary-border-color: lighten($accent-color, 5%);
    //#eea236;
    $btn-primary-hover-color: #fff;

    $btn-secondary-color: #5a6268;
    $btn-secondary-background-color: #ffffff;
    $btn-secondary-border-color: lightgrey;
        
    $btn-secondary-hover-color: #fff;
    $btn-secondary-hover-background-color: $btn-primary-border-color;
    $btn-secondary-hover-border-color: $btn-primary-border-color;

    $btn-info-color: #fff;
    $btn-info-background-color: $accent-color;
    $btn-info-border-color: $btn-primary-border-color;
    $btn-info-hover-color: #fff;
    $btn-info-hover-background-color: $btn-primary-border-color;
    $btn-info-hover-border-color: $btn-primary-border-color;

    $btn-outline-secondary: lightgrey;

    // ** general link color **
    $general-link-color : $accent-color;

    // ** table **   
    $table-header-background-color : $tabel-accrordion-header;  
    $table-tr-odd-background-color : $table-stripped-row-background-color;
    $pagination-color: #000000;
    $table-header-text-color: white;

    // ** Menu sidebar **
    $menu-nav-background-color: $menu-background-color;
    $submenu-background-color: $submenu-items-background-color;
    $active-hover-link-background-color: $active-menu-item-background-color;
    $active-link-border-left: $accent-color;
    
    $text-color-dark: #D3DA74;
    $text-color-light: #fff;
    $minimal-menu-button-background-color: $accent-color;

    // ** accordion **  
    $accordion-header-background-color: $tabel-accrordion-header;
    $accordion-background-color: #ffffff;
    $accordion-color: #ffffff;

    // ** top navigation **
    $topnav-background-color: #ffffff;
    $topnav-box-shadow-color: #a8a8a8;

    @import "common_custom";    
