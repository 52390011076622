.logo-image {
    content: url($logo);
}
html, body{
    font-family: $font-family;
}
.login-page .row{
    background: $login-background;
}

h2, .h2 {
    font-size: $main-page-header-font-size;
}
a {
    color: $general-link-color;
    text-decoration: none !important;
}
// table
.ng2-smart-sort-link.sort, .ng2-smart-sort {
    color: #ffffff;
}
thead > tr > th {
    background-color: $table-header-background-color;
    color:  $table-header-text-color;
    font-size: $table-header-font-size;
}
.ng2-smart-th  {
    font-size: $table-header-font-size;
    color: black;
}
tbody > tr {
    &:nth-child(odd) {
        // original: whitesmoke;
        background-color: $table-tr-odd-background-color;
    }
}
.ng2-smart-page-link.page-link {
    color: $pagination-color;
}
.clickable-rows tr.ng2-smart-row:hover {
    cursor: pointer;
}
// btn
.btn-primary{
    background-color: $btn-primary-background-color;
    border-color: $btn-primary-border-color;
}
.btn-primary:hover {
    color: $btn-primary-hover-color;
    background-color: $btn-primary-border-color;
    border-color: $btn-primary-border-color;
}

.btn-secondary {
    color: $btn-secondary-color;
    background-color: $btn-secondary-background-color;
    border-color: $btn-secondary-border-color;
}
.btn-secondary:hover {
    color: $btn-secondary-hover-color;
    background-color: $btn-secondary-hover-background-color;
    border-color: $btn-secondary-hover-border-color;
}
.btn-outline-secondary{
    border-color: $btn-outline-secondary;
}
.btn-info {
    color: $btn-info-color;
    background-color: $btn-info-background-color;
    border-color: $btn-info-border-color;
}
.btn-info:hover {
    color: $btn-info-hover-color;
    background-color: $btn-info-hover-background-color;
    border-color: $btn-info-hover-border-color;
}
// menu side
.sidebar{
    background-color: $menu-nav-background-color;
    .menu-button {
        background: $menu-nav-background-color;
        border: none;
        position: absolute; right: 0;
        font-size: 24px;
        em {
            color: $text-color-light;
        }
    }
    .list-group{
        a.list-group-item {
            background: $menu-nav-background-color;
            color: $text-color-dark;
        }
        a:hover {
            background: $active-hover-link-background-color;
            color: $text-color-light;
        }
        a.router-link-active {
            background: $active-hover-link-background-color;
            color: $text-color-light;
            border-left: 4px solid $active-link-border-left;
        }
    }
    .sidebar-dropdown {
        .panel-title {
            a {
                color: $text-color-dark;
                background: $menu-nav-background-color;
            }
            a:hover, a:focus {
                color: $text-color-light;
            }
        }
        .panel-title:hover {
            background: darken($menu-nav-background-color, 5%);
        }
   /*     .panel-collapse {
            .panel-body {
                .list-group-item {
                    background-color: $menu-nav-background-color;
                    a {
                        color: $text-color-dark;
                    }
                    a:hover {
                        color: $text-color-light;
                    }
                }
                .list-group-item:hover {
                    background: darken($menu-nav-background-color, 5%);
                }
            }
        }*/
    }
}
.nested-menu {
    ul.submenu {
        background-color: $submenu-background-color;
    }
    & .expand {
        ul.submenu {
            li {
                a {
                    color: $text-color-light;
                }
                a.router-link-active {
                    background: $active-hover-link-background-color;
                    color: $text-color-light;
                    border-left: 4px solid $active-link-border-left;
                }
            }
        }
    }
}
.minimal-menu{
    background-color: $menu-nav-background-color;
    .menu-button{
        background: $menu-nav-background-color;
        position: absolute; right: 0; top:-100;
        font-size: 24px;
        border: none;
        em {
            color: $text-color-light;
        }
    }
    .list-group{
        a.list-group-item {
            background: $menu-nav-background-color;
            color: $text-color-dark;
        }
        a:hover {
            background: darken($menu-nav-background-color, 5%);
            color: $text-color-light;
        }
        a.router-link-active {
            background: $active-hover-link-background-color;
            color: $text-color-light;
            border-left: 4px solid $active-link-border-left;
        }
    }
    .sidebar-dropdown {
        .panel-title {
            a {
                color: $text-color-dark;
                background: $menu-nav-background-color;
            }
            a:hover, a:focus {
                color: $text-color-light;
            }
        }
        .panel-title:hover {
            background: darken($menu-nav-background-color, 5%);
        }
        .panel-collapse {
            .panel-body {
                .list-group-item {
                    background-color: $menu-nav-background-color;
                    a {
                        color: $text-color-dark;
                    }
                    a:hover {
                        color: $text-color-light;
                    }
                }
                .list-group-item:hover {
                    background: darken($menu-nav-background-color, 5%);
                }
            }
        }
    }
    .nested-menu {
        ul.submenu {
            background-color: $submenu-background-color;
        }
        & .expand {
            ul.submenu {
                li {
                    a {
                        color: $text-color-light;
                    }
                    a.router-link-active {
                        background: $active-hover-link-background-color;
                        color: $text-color-light;
                        border-left: 4px solid $active-link-border-left;
                    }
                }
            }
        }
    }
}
// accordions (historian)
.historian ngb-accordion > .card {
    background-color: $accordion-header-background-color;
}
.historian .card-body {
    background-color: $accordion-background-color;
}
.historian .card > div > a,
.historian .card > div > a:hover,
.historian .card > div > a:visited,
.historian .card > div > a:focus {
    color: $accordion-color;
}
// breadcrumb
.breadcrumb {
    background-color: $breadcrumb-background-color;
}
@media screen and (max-width: 959px) {
    div.sidebar-toggle > .menu-button {
        // background: #ed681b;
        background-color: $minimal-menu-button-background-color;
        em {
            color: $text-color-light;
        }
    }
}
.topnav {
    border-radius: 0;
    background-color: $topnav-background-color;
}
.header .navbar {
    background: $topnav-background-color !important;
    box-shadow: 0 1px 10px $topnav-box-shadow-color;
}

.required {
    color: red;
    font-size: smaller;
    height: 16px;
}

.btn:disabled,
.btn.disabled{
  color:#fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.btn:disabled:hover,
.btn:disabled:focus,
.btn.disabled:hover,
.btn.disabled:focus {
  color:#fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}